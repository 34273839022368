@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth;
}

@font-face {
  font-family: "Satoshi-Variable";
  src: url("./fonts/Satoshi-Variable.woff2") format("woff2"),
    url("./fonts/Satoshi-Variable.woff") format("woff"),
    url("./fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-VariableItalic";
  src: url("./fonts/Satoshi-VariableItalic.woff2") format("woff2"),
    url("./fonts/Satoshi-VariableItalic.woff") format("woff"),
    url("./fonts/Satoshi-VariableItalic.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Light";
  src: url("./fonts/Satoshi-Light.woff2") format("woff2"),
    url("./fonts/Satoshi-Light.woff") format("woff"),
    url("./fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-LightItalic";
  src: url("./fonts/Satoshi-LightItalic.woff2") format("woff2"),
    url("./fonts/Satoshi-LightItalic.woff") format("woff"),
    url("./fonts/Satoshi-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Regular";
  src: url("./fonts/Satoshi-Regular.woff2") format("woff2"),
    url("./fonts/Satoshi-Regular.woff") format("woff"),
    url("./fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-Italic";
  src: url("./fonts/Satoshi-Italic.woff2") format("woff2"),
    url("./fonts/Satoshi-Italic.woff") format("woff"),
    url("./fonts/Satoshi-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Medium";
  src: url("./fonts/Satoshi-Medium.woff2") format("woff2"),
    url("./fonts/Satoshi-Medium.woff") format("woff"),
    url("./fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-MediumItalic";
  src: url("./fonts/Satoshi-MediumItalic.woff2") format("woff2"),
    url("./fonts/Satoshi-MediumItalic.woff") format("woff"),
    url("./fonts/Satoshi-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Bold";
  src: url("./fonts/Satoshi-Bold.woff2") format("woff2"),
    url("./fonts/Satoshi-Bold.woff") format("woff"),
    url("./fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-BoldItalic";
  src: url("./fonts/Satoshi-BoldItalic.woff2") format("woff2"),
    url("./fonts/Satoshi-BoldItalic.woff") format("woff"),
    url("./fonts/Satoshi-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Black";
  src: url("./fonts/Satoshi-Black.woff2") format("woff2"),
    url("./fonts/Satoshi-Black.woff") format("woff"),
    url("./fonts/Satoshi-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-BlackItalic";
  src: url("./fonts/Satoshi-BlackItalic.woff2") format("woff2"),
    url("./fonts/Satoshi-BlackItalic.woff") format("woff"),
    url("./fonts/Satoshi-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  width: 100%;
  height: 52rem;
  padding: 2rem 0;
  position: relative;
  background: #0f041f;
  border-radius: 1rem;
}

@media (max-width: 1070px) {
  .swiper_container {
    width: 100%;
  }
}

.swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
  margin: auto;
  align-content: center;
}

@media (max-width: 1070px) {
  .swiper-slide {
    margin: auto;
  }
  .swiper-slide #mainImg {
    width: 25rem !important;
    height: 37.5rem !important;
    object-fit: cover;
  }

  .swiper-slide img {
    width: 35px;
    height: 580px !important;
    object-fit: cover;
    border-radius: 8px;
  }
}

@media (max-width: 717px) {
  .swiper-slide #mainImg {
    width: 14rem !important;
    height: 21.375rem !important;
    object-fit: cover;
    border-radius: 4.507px;
  }
  .swiper-slide img {
    width: 100% !important;
    height: 16.75rem !important;
    object-fit: cover;
    border-radius: 4.507px;
  }

  .swiper_container {
    width: 100%;
    height: 442px;
    padding: 2rem 0;
    position: relative;
    background: #0f041f;
    border-radius: 1rem;
  }
}

.swiper-slide img {
  width: 35rem;
  height: 42rem;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 10px;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  justify-content: center;
  bottom: 2rem;
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2px;
  color: red;
}

.swiper-button-next ion-icon {
  background: red;
  color: red;
  z-index: 10;
  font-size: 2px;
}

.slider-controler {
  margin-top: 20px;
}

.swiper-pagination {
  position: relative;
  width: 15rem;
  bottom: 1rem;
}

#Icon {
  background: #000;
  width: 20px;
}

.swiper-pagination .swiper-pagination-bullet {
  background: #fff;
  border: 1px solid #ceb0fa;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #fff;
  border: 1.6px solid #9654f4;
}

@keyframes shimmer-slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes spin-around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-shimmer-slide {
  animation: shimmer-slide var(--speed) linear infinite;
}

.animate-spin-around {
  animation: spin-around var(--speed) linear infinite;
}

.size-full {
  width: 100%;
  height: 100%;
}

.inset-0 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
